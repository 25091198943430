import CalendarInput from '@rsa-digital/evo-shared-components/components/Form/CalendarInput';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import {
  addDaysToDate,
  localDateToUtcDate,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import React, { useEffect, useState } from 'react';
import QuestionField from 'components/QuestionField';
import useAboutYourPolicyQuestions from 'forms/AboutYourPolicyForm/questions';
import { getDifferenceInDates } from 'helpers/dateHelpers';
import { trackCalendarClick } from 'helpers/eventTracking';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import { usePolicyDetails } from 'state/formData/policyDetails';

type CoverStartDateQuestionProps = {
  minDate?: Date;
  explanatoryText?: string;
  questionText?: string;
  onChangeCallback?: () => void;
  disableCalendar?: boolean;
  hideQuestionText?: boolean;
} & ComponentProps;

const CoverStartDateQuestion: React.FC<CoverStartDateQuestionProps> = ({
  minDate,
  explanatoryText,
  questionText,
  onChangeCallback,
  disableCalendar,
  hideQuestionText,
  ...props
}) => {
  const [policyDetails, updatePolicyDetails] = usePolicyDetails();
  const questions = useAboutYourPolicyQuestions();
  const disableDateChecks = useDisableDateChecks();
  const todayUtc = localDateToUtcDate(new Date());

  const { policyInfo } = useCurrentQuote();
  const quoteStartDate = parseDate(policyInfo?.quoteStartDate ?? new Date());
  const [coverStartDateUtc, setCoverStartDateUtc] = useState(
    new Date(policyDetails.coverStartDate)
  );

  const lastDate = new Date(questions.switch_over_date);
  const daysUntilExit = getDifferenceInDates(todayUtc, lastDate);
  const lastCheckableDate =
    daysUntilExit < 30 ? lastDate : addDaysToDate(localDateToUtcDate(quoteStartDate), 30);

  useEffect(() => {
    const newDate = new Date(policyDetails.coverStartDate);
    const requiredDate = (minDate || todayUtc) > newDate ? minDate || todayUtc : newDate;
    if (coverStartDateUtc.getTime() !== requiredDate.getTime())
      setCoverStartDateUtc(requiredDate);
  }, [policyDetails, minDate, todayUtc, coverStartDateUtc]);

  return (
    <QuestionField
      {...componentProps(props)}
      question={{
        ...questions.coverStartDate,
        questionText: !hideQuestionText
          ? questionText ?? questions.coverStartDate.questionText
          : '',
        explanatoryText: !hideQuestionText
          ? explanatoryText ?? questions.coverStartDate.explanatoryText
          : '',
      }}>
      <CalendarInput
        id="coverStartDate"
        value={disableCalendar ? undefined : coverStartDateUtc}
        minDate={minDate || todayUtc}
        /* The API treats the max date as UTC quoteStartDate + 30 days, so we must add the +30 days from UTC midnight, 
          and NOT from the local date (which could be ahead, i.e. BST 12am-1am). */
        maxDate={disableDateChecks ? undefined : lastCheckableDate}
        onChange={(newValue) => {
          trackCalendarClick('coverStartDate', newValue);
          updatePolicyDetails({ coverStartDate: newValue.toISOString() });
          onChangeCallback?.();
        }}
        ariaPrefix="Cover start date"
        disableCalendar={disableCalendar}
      />
    </QuestionField>
  );
};

export default CoverStartDateQuestion;
