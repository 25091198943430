export const dateReplacer = (
  dateText: string,
  csString: string,
  dateWithoutYearText?: string
): string => {
  const processedString = csString
    .replace(/^({{date}})/, dateText)
    .replace(/({{date}})/g, dateText);
  if (dateWithoutYearText) {
    return processedString
      .replace(/^({{dateWithoutYear}})/, dateWithoutYearText)
      .replace(/({{dateWithoutYear}})/g, dateWithoutYearText);
  }
  return processedString;
};
